import house1 from "./media/image1.jpg";
import house2 from "./media/image2.jpg";
import house3 from "./media/image3.jpg";
import house4 from './media/image4.jpg';
import house5 from './media/image5.jpg';
import house6 from './media/image6.jpg';
import house7 from './media/image7.jpg';
import house8 from './media/image8.png';
import house9 from './media/image9.jpg';
import depan from './media/depan.jpeg'
import depan2 from './media/depanok.jpeg'

export const properties = [
  {
    id: "1",
    img: depan,
  },

  {
    id: "2",
    img: depan2,
  },

  {
    id: "3",
    img: house3,
  },
  {
    id: "4",
    img: house4,
  },
  {
    id: "5",
    img: house5,
  },
  {
    id: "6",
    img: house6,
  },
  {
    id: "7",
    img: house7,
  },
  {
    id: "8",
    img: house8,
  },
  {
    id: "9",
    img: house9,
  },
];
